/* font-family: 'Cormorant SC', serif;
font-family: 'Lato', sans-serif; */
/* add: 'https://madhatter.dev/' to background for deployment on GitHub */
body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  background-image: url("https://madhatter.dev/paper-background.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.main {
  display: grid;
  grid-template-columns: minmax(1110px, 1fr);
  grid-template-areas: "body";
}

.content-wrapper {
  grid-area: body;
}

.text {
  color: #fff;
  letter-spacing: 1.6px;
  line-height: 1.6px;
}

.mh-text {
  font-family: "Cormorant SC", serif;
}

/* ******** MAIN TITLES */
.title-block-wrapper {
  grid-area: body;
  min-height: 8rem;
  display: grid;
  padding: 3rem 0.5rem;
  grid-template-columns: 0.2fr 1fr 1fr 0.2fr;
  grid-template-areas: "t-l-space image title t-r-space";
}

.title-img-wrapper {
  grid-area: image;
  height: 35rem;
}

.title-img {
  height: 100%;
  /* transform: scaleX(-1); */
}

.title-text-wrapper {
  grid-area: title;
  padding-top: 3rem;
}

.do-title {
  position: relative;
  top: -35rem;
  left: 27rem;
  text-align: left;
  font-size: 2.2em;
  padding: 0.2rem 0;
}

.name-title,
.welcome-text {
  color: black;
  text-align: center;
  align-self: center;
  justify-self: center;
}

.welcome-text {
  letter-spacing: 0.4em;
  padding: 1em;
}

.name-title {
  margin-top: 4rem;
  padding-top: 30px;
  font-family: "Cormorant SC", serif;
  font-size: 300%;
}

.title-icon-wrapper {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.menu-card {
  border: solid 0.08rem black;
  border-radius: 5%;
}

/* *********** ABOUT STYLES */
.about-wrapper {
  background-color: rgb(29, 72, 155, 0.5);
  padding: 0rem;
  text-align: center;
  letter-spacing: 1.6px;
  line-height: 1.6;
  display: grid;
  grid-template-columns: 0.8fr 1fr 2fr 0.3fr;
  grid-template-areas: "ab-l-s ab-card ab-text ab-r-s";
}

.about-menu-card-wrapper {
  grid-area: ab-card;
  height: 30rem;
  width: 22rem;
}

.top-hat-card-wrapper {
  height: 30rem;
}

.top-hat-card-about {
  margin-top: 3rem;
  height: 60%;
}

.about-text-wrapper {
  grid-area: ab-text;
  color: white;
  padding: 3em;
  border-radius: 3%;
}

/* ******** Project Styles */
.project-title-wrapper {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.75fr;
  grid-template-areas: "left-space p-mh-title right-space";
}

.title-card {
  display: inline-block;
  position: relative;
  color: black;
  left: 11.3rem;
  top: 0rem;
  height: 18.5rem;
  margin-top: 2rem;
  margin-bottom: 8rem;
}

.project-section-title {
  display: inline-block;
  position: relative;
  color: black;
  left: 1.1rem;
  top: -11.5rem;
}

.project-selection-sub-title {
  display: inline-block;
  position: relative;
  color: black;
  left: -5.1rem;
  top: -10.1rem;
}

.project-second-img {
  position: relative;
  top: 0;
  left: 0;
  height: 40rem;
  width: 40rem;
}

.project-litho {
  height: 59%;
  display: inline-block;
  position: absolute;
  left: 3rem;
  top: 72rem;
}

.tea-party-card {
  grid-area: p-mh-title;
  text-align: center;
  height: 28rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  margin-bottom: 0;
}

.projects-wrapper {
  background-color: rgb(29, 72, 155, 0.5);
  margin-top: 3.5rem;
  padding: 1rem;
  padding-top: 4.5rem;
}

.project-detail-div {
  border-bottom: solid 0.1rem black;
  padding: 1.3rem;
  letter-spacing: 1.3px;
  line-height: 1.2;
  color: white;
}

.last {
  border: none;
}

.project-header {
  margin-top: 0;
}

.project-info-wrapper {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "pd-text pd-image";
}

.project-detail-text {
  grid-area: pd-text;
  padding: 0 0.5rem;
  font-size: 150%;
  white-space: pre-wrap;
}

.project-detail-title {
  margin-top: 0;
  font-size: 150%;
}

.project-screen-shot {
  grid-area: pd-image;
  text-align: center;
}

.p-image-detail {
  height: 30rem;
}

.project-detail-desc {
  font-size: 95%;
  font-weight: 500;
  line-height: 140%;
}

.project-detail-tech {
  font-size: 85%;
  font-weight: 500;
  line-height: 140%;
}

/* ******* BLOG STYLES */
.blog-title-wrapper {
  margin-top: 3rem;
  height: 34rem;
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.5fr;
  grid-template-areas: "marg-left b-mh-title marg-right";
}

.blog-second-img {
  grid-area: b-mh-title;
  height: 36rem;
}

.blog-second-img img {
  height: 100%;
}

.blogs-section-title {
  color: black;
  display: inline-block;
  position: relative;
  left: 11.8rem;
  top: -8.2rem;
}

.blog-sub-heading {
  color: black;
  letter-spacing: 1.5px;
  display: inline-block;
  position: relative;
  left: 5.7rem;
  top: -6.2rem;
}

.blogs-details-wrapper {
  background-color: rgb(29, 72, 155, 0.5);
  padding: 1rem 2rem;
}

.blog-detail-wrapper {
  margin: 0.5em;
  padding: 0.8em;
  font-size: 125%;
  line-height: 140%;
}

.blog-detail-header {
  margin: 0;
  margin-bottom: 0.5em;
  letter-spacing: 1.3px;
  line-height: 1.2;
}

.blog-detail-desc {
  margin: 0;
  font-weight: 150;
  color: #fff;
  opacity: 0.8;
}

/* ********* CONTACT STYLES */
.contact-wrapper {
  justify-self: center;
  justify-content: center;
  margin: 3rem 0;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  grid-template-areas: "left contact-content contact-mh-image right";
}

.contact-header {
  color: black;
  font-size: 2.5em;
  text-align: right;
  margin: 3rem 0;
}

.contact-content {
  grid-area: contact-content;
}

.contact-icon-wrapper {
  padding: 0.5em;
  display: grid;
  grid-template-columns: auto 0.5fr 0.5fr 0.5fr 0.5fr auto;
  grid-column-gap: 0.1fr;
  grid-template-areas: " left github linkedin email resume right";
}

.git-hub-wrapper {
  grid-area: github;
  justify-self: center;
}

.linked-in-wrapper {
  grid-area: linkedin;
  justify-self: center;
}

.email-icon-wrapper {
  grid-area: email;
  justify-self: center;
  align-items: center;
}

.resume-icon-wrapper {
  grid-area: resume;
  justify-self: center;
  align-items: center;
}

.git-hub-icon,
.linked-in-icon,
.resume-icon {
  height: 6em;
  width: 6em;
  margin: 0;
  justify-self: center;
}

.email-icon {
  height: 6em;
  width: 6em;
  margin: 0;
}

.contact-mh-image {
  grid-area: contact-mh-image;
  height: 20rem;
}

.contact-mh-image img {
  height: 100%;
}

/* ****** Width Less than 750px ****** */
@media (max-width: 749px) {
  .title-block-wrapper {
    grid-area: body;
    min-height: 8rem;
    display: grid;
    padding: 3rem 0.5rem;
    grid-template-rows: 0.2fr 1fr 1fr 0.2fr;
    grid-template-columns: 0.15fr 1fr 0.15fr;
    grid-template-areas: "t-top t-top t-top" ". image ." ". title ." "t-bottom t-bottom t-bottom";
  }

  .title-img-wrapper {
    grid-area: image;
    height: 35rem;
  }

  .do-title {
    position: relative;
    top: -35rem;
    left: 27rem;
    text-align: left;
    font-size: 2rem;
    padding: 0.2rem 0;
  }

  .welcome-text {
    letter-spacing: 0.4em;
    font-size: 200%;
    padding: 1em;
  }

  .name-title {
    margin-top: 4rem;
    padding-top: 45px;
    font-family: "Cormorant SC", serif;
    font-size: 700%;
  }
  .title-icon-wrapper {
    width: 87%;

    margin-left: auto;
    margin-right: auto;
  }
  .git-hub-icon,
  .linked-in-icon,
  .resume-icon {
    height: 9em;
    width: 9em;
    margin: 0;
    justify-self: center;
  }

  .email-icon {
    height: 9em;
    width: 9em;
    margin: 0;
  }

  .about-wrapper {
    background-color: rgb(29, 72, 155, 0.5);
    padding: 1em;
    padding-bottom: 0;
    text-align: center;
    letter-spacing: 1.6px;
    line-height: 1.6;
    display: grid;
    grid-template-rows: 0fr 0fr 1fr 0.05fr;
    grid-template-columns: 0.15fr 1fr 0.15fr;
    grid-template-areas:
      ". ab-top ."
      ". ab-card ."
      ". ab-text ."
      ". ab-bottom .";
  }

  .about-menu-card-wrapper {
    grid-area: ab-card;
    justify-self: center;
    justify-content: center;
    text-align: center;
    height: 40rem;
    width: 32rem;
  }
  .top-hat-card-about {
    margin-right: auto;
    margin-left: auto;
    height: 76%;
  }

  .about-text-wrapper {
    font-size: 2rem;
    font-weight: 800;
  }

  .project-info-wrapper {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 0fr;
    grid-template-rows: 0.7fr 1fr;
    grid-template-areas: "pd-text" "pd-image";
    font-size: 1.5rem;
  }

  .blogs-details-wrapper {
    font-size: 2rem;
    font-weight: 800;
  }

  .contact-wrapper {
    grid-template-columns: 0.75fr 0.1fr 1fr 0.5fr;
  }
}
